<template>
    <div class=container>
        <table class=table border="0" cellpadding="0" cellspacing="30" width="709">
<tbody><tr>
<td>
  <div align="center">
  <font size="+2"></font>
  </div>

<br>
<br>

<h3>Copyright</h3>
<font face="Verdana, Arial, Helvetica, sans-serif">
The TVBRDF database is the property of Columbia University. This 
data can only be used for research or academic purposes. Any 
commercial use of the data whatsoever or incorporation of the data 
into a larger database intended for public distribution must be 
done with the explicit written consent of the 
<a href="mailto:tvbrdf@cs.columbia.edu">TVBRDF administrators</a>.<br>
<br>
              </font><hr>
<font face="Verdana, Arial, Helvetica, sans-serif">              </font><h3><font face="Verdana, Arial, Helvetica, sans-serif">List of Samples</font></h3>
<table border="1" cellpadding="0" cellspacing="0" width="100%">
                <tbody align="center">
                  <tr>
                    <td width="128"><b>Rendered Spheres<br>(Complex Lighting)</b></td>
                    <td width="110"><b>Scans /<br> Interval(mins)</b></td>
                    <td width="200"><b>Description</b></td>
                    <td valign="middle" width="105"><b>Download</b></td>
                    <td width="50"><div align="center"><b>Size (KB)</b></div></td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>- / -</td>
                    <td>Complete Dataset</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/allsamples.zip">allsamples.zip</a></td>
                    <td>32,392</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample01.gif"></td>
                    <td>24 / 1</td>
                    <td>Krylon Spray Paint - Flat White</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample01.zip">sample01.zip</a></td>
                    <td>715</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample02.gif"></td>
                    <td>27 / 1</td>
                    <td>Krylon Spray Paint - Satin Green</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample02.zip">sample02.zip</a></td>
                    <td>717</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>40 / 1</td>
                    <td>Krylon Spray Paint - Glossy Blue</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample03.zip">sample03.zip</a></td>
                    <td>1,055</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>41 / 1</td>
                    <td>Krylon Spray Paint - Glossy Red</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample04.zip">sample04.zip</a></td>
                    <td>1,079</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample05.gif"></td>
                    <td>33 / 1</td>
                    <td>Krylon Spray Paint - Satin Dove-Teal</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample05.zip">sample05.zip</a></td>
                    <td>751</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample06.gif"></td>
                    <td>40 / 1</td>
                    <td>Krylon Spray Paint - Flat Yellow</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample06.zip">sample06.zip</a></td>
                    <td>1,147</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample07.gif"></td>
                    <td>21 / 1</td>
                    <td>Crayola Water Color - Blue</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample07.zip">sample07.zip</a></td>
                    <td>604</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample08.gif"></td>
                    <td>30 / 1</td>
                    <td>Crayola Water Color - Red</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample08.zip">sample08.zip</a></td>
                    <td>854</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample09.gif"></td>
                    <td>30 / 1</td>
                    <td>Crayola Water Color - Green</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample09.zip">sample09.zip</a></td>
                    <td>849</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample10.gif"></td>
                    <td>40 / 1</td>
                    <td>Crayola Water Color - Purple</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample10.zip">sample10.zip</a></td>
                    <td>1,140</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample11.gif"></td>
                    <td>40 / 1</td>
                    <td>Crayola Water Color - Orange</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample11.zip">sample11.zip</a></td>
                    <td>1,135</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample12.gif"></td>
                    <td>40 / 1</td>
                    <td>Crayola Water Color - Light Green</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample12.zip">sample12.zip</a></td>
                    <td>1,146</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample13.gif"></td>
                    <td>40 / 1</td>
                    <td>Crayola Water Color - Yellow</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample13.zip">sample13.zip</a></td>
                    <td>1,156</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample14.gif"></td>
                    <td>10 / 1</td>
                    <td>Daler-Rowney Oil Color - Prussian Green</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample14.zip">sample14.zip</a></td>
                    <td>250</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample15.gif"></td>
                    <td>10 / 1</td>
                    <td>Daler-Rowney Oil Color - Prussian Red</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample15.zip">sample15.zip</a></td>
                    <td>261</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample16.gif"></td>
                    <td>40 / 1</td>
                    <td>Daler-Rowney Oil Color - Permanent Light Green</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample16.zip">sample16.zip</a></td>
                    <td>1,124</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample17.gif"></td>
                    <td>40 / 1</td>
                    <td>Daler-Rowney Oil Color - Cadmium Yellow</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample17.zip">sample17.zip</a></td>
                    <td>1,146</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample18.gif"></td>
                    <td>31 / 5</td>
                    <td>Alme Grey Blue Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample18.zip">sample18.zip</a></td>
                    <td>911</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample19.gif"></td>
                    <td>41 / 5</td>
                    <td>Idemo Beige Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample19.zip">sample19.zip</a></td>
                    <td>1,237</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>40 / 5</td>
                    <td>Ingebo Dark Red Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample20.zip">sample20.zip</a></td>
                    <td>1,170</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>20 / 2</td>
                    <td>Pink Denim Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample21.zip">sample21.zip</a></td>
                    <td>614</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample22.gif"></td>
                    <td>41 / 1</td>
                    <td>Orange Cotton Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample22.zip">sample22.zip</a></td>
                    <td>1,237</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample23.gif"></td>
                    <td>40 / 3</td>
                    <td>Beige Cotton Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample23.zip">sample23.zip</a></td>
                    <td>1,217</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample24.gif"></td>
                    <td>40 / 1</td>
                    <td>Pink Cotton Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample24.zip">sample24.zip</a></td>
                    <td>1,201</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample25.gif"></td>
                    <td>40 / 1</td>
                    <td>White Plaster Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample25.zip">sample25.zip</a></td>
                    <td>1,243</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample26.gif"></td>
                    <td>30 / 5</td>
                    <td>Clay Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample26.zip">sample26.zip</a></td>
                    <td>914</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample27.gif"></td>
                    <td>30 / 5</td>
                    <td>Cement Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample27.zip">sample27.zip</a></td>
                    <td>913</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample28.gif"></td>
                    <td>15 / -</td>
                    <td>Joint Compound on Electric Red Exterior Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample28.zip">sample28.zip</a></td>
                    <td>451</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample29.gif"></td>
                    <td>10 / -</td>
                    <td>Joint Compound on Krylon Satin Red Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample29.zip">sample29.zip</a></td>
                    <td>286</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample30.gif"></td>
                    <td>15 / -</td>
                    <td>Joint Compound on Krylon Satin Dove-Teal Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample30.zip">sample30.zip</a></td>
                    <td>463</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample31.gif"></td>
                    <td>15 / -</td>
                    <td>Joint Compound on Rust-Oleum Flat Yellow Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample31.zip">sample31.zip</a></td>
                    <td>466</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample32.gif"></td>
                    <td>13 / -</td>
                    <td>Joint Compound on Almas Red Fabric</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample32.zip">sample32.zip</a></td>
                    <td>405</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample33.gif"></td>
                    <td>15 / -</td>
                    <td>Joint Compound on Green-Grey Metallic Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample33.zip">sample33.zip</a></td>
                    <td>435</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample34.gif"></td>
                    <td>10 / -</td>
                    <td>Vacuum Dust on Electric Red Exterior Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample34.zip">sample34.zip</a></td>
                    <td>298</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample35.gif"></td>
                    <td>10 / -</td>
                    <td>Vacuum Dust on Krylon Satin Red Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample35.zip">sample35.zip</a></td>
                    <td>276</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample36.gif"></td>
                    <td>9 / -</td>
                    <td>Vacuum Dust on Krylon Satin Dove-Teal Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample36.zip">sample36.zip</a></td>
                    <td>270</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample37.gif"></td>
                    <td>11 / -</td>
                    <td>Vacuum Dust on Rust-Oleum Flat Yellow Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample37.zip">sample37.zip</a></td>
                    <td>335</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample38.gif"></td>
                    <td>10 / -</td>
                    <td>Vacuum Dust on Almas Red Fabric</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample38.zip">sample38.zip</a></td>
                    <td>300</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample39.gif"></td>
                    <td>10 / -</td>
                    <td>Vacuum Dust on Green-Grey Metallic Paint</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample39.zip">sample39.zip</a></td>
                    <td>280</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample40.gif"></td>
                    <td>31 / 1</td>
                    <td>Hershey's Milk Chocolate Melting</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample40.zip">sample40.zip</a></td>
                    <td>912</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/sample41.gif"></td>
                    <td>46 / 3</td>
                    <td>Red Wine on White Fabric Drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/sample41.zip">sample41.zip</a></td>
                    <td>1,425</td>
                  </tr>

                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/download_files/no_sphere.gif"></td>
                    <td>1 / -</td>
                    <td>Spectralon</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/downloads/spectralon.zip">spectralon.zip</a></td>
                    <td>28</td>
                  </tr>

                  <tr>
                    <td><b>Rendered Spheres<br>(Complex Lighting)</b></td>
                    <td><b>Scans /<br> Interval(mins)</b></td>
                    <td><b>Description</b></td>
                    <td><b>Download</b></td>
                    <td><b>Size (KB)</b></td>

                  </tr>
                </tbody>
              </table></td>
          </tr>

<tr>
  <td>
  <hr style="width: 100%; height: 2px;">
  <router-link to="/repository/TVBRDF">TVBRDF Database Home</router-link><br>
  Contact:&nbsp;<a href="mailto:tvbrdf@cs.columbia.edu">tvbrdf@cs.columbia.edu</a><br>
  Last modified: 06/03/2007
  </td>
</tr>

</tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>